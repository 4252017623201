<template>
  <PurchaseConfirmation
      document-name="Агентское распоряжение на приобретение прав требования без гарантии обратного выкупа (скачать PDF)"
      seller-document-name="Оферта о заключении договора уступки прав требований (цессии) без обязательства обратного выкупа"
  />
</template>
<script>
import PurchaseConfirmation from "./PurchaseConfirmation";
export default {
  components: {PurchaseConfirmation}
}
</script>